import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockText from '../components/block-text'

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityContact {
        id
        title
				thankyou_title
				_rawThankyouText
      }
    }
  `)
  const { thankyou_title, _rawThankyouText } = data.sanityContact

  return (
    <Layout>
      <SEO title={thankyou_title} />
      <div className="container-theme">
        <div className="thankyou-page container-fluid">
          <section className="thankyou-page-top row">
            <div className="thankyou col-md-4 offset-3 col-sm-5 col-6-mob offset-0-mob">
              <h1 className="title">{thankyou_title}</h1>
              <BlockText className="thankyou-text" blocks={_rawThankyouText} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
